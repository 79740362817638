var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":20,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Campos de formulario")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(_vm.items.filter(function (x) { return x.edited; }).length === 0)?_c('v-btn',{attrs:{"color":"primary","dark":"","dense":""},on:{"click":_vm.handleAddItem}},[_vm._v(" A�adir ")]):_vm._e()],1)]},proxy:true},{key:"item.Name",fn:function(ref){
var item = ref.item;
return [(!item.edited)?_c('span',[_vm._v(" "+_vm._s(item.Name)+" ")]):_c('v-text-field',{attrs:{"outlined":"","small":"","dense":"","hide-details":""},model:{value:(item.Name),callback:function ($$v) {_vm.$set(item, "Name", $$v)},expression:"item.Name"}})]}},{key:"item.Class",fn:function(ref){
var item = ref.item;
return [(!item.edited)?_c('span',[_vm._v(" "+_vm._s(item.Class)+" ")]):_c('v-text-field',{attrs:{"outlined":"","small":"","dense":"","hide-details":""},model:{value:(item.Class),callback:function ($$v) {_vm.$set(item, "Class", $$v)},expression:"item.Class"}})]}},{key:"item.Type",fn:function(ref){
var item = ref.item;
return [(!item.edited)?_c('span',[_vm._v(" "+_vm._s(_vm.getType(item.Type))+" ")]):_c('v-select',{attrs:{"items":_vm.types,"outlined":"","hide-details":"","dense":"","itemText":"name","itemValue":"id"},model:{value:(item.Type),callback:function ($$v) {_vm.$set(item, "Type", $$v)},expression:"item.Type"}})]}},{key:"item.Value",fn:function(ref){
var item = ref.item;
return [(!item.edited && item.Type !== 'image')?_c('span',[_vm._v(" "+_vm._s(item.Value)+" ")]):(item.Type !== 'image')?_c('v-text-field',{attrs:{"outlined":"","small":"","dense":"","hide-details":""},model:{value:(item.Value),callback:function ($$v) {_vm.$set(item, "Value", $$v)},expression:"item.Value"}}):_vm._e()]}},{key:"item.Size",fn:function(ref){
var item = ref.item;
return [(!item.edited && item.Type !== 'image')?_c('span',[_vm._v(" "+_vm._s(item.Size)+" ")]):(item.Type !== 'image')?_c('v-text-field',{attrs:{"outlined":"","small":"","dense":"","hide-details":""},model:{value:(item.Size),callback:function ($$v) {_vm.$set(item, "Size", $$v)},expression:"item.Size"}}):_vm._e()]}},{key:"item.Width",fn:function(ref){
var item = ref.item;
return [(!item.edited && item.Type === 'image')?_c('span',[_vm._v(" "+_vm._s(item.Width)+" ")]):(item.Type === 'image')?_c('v-text-field',{attrs:{"outlined":"","small":"","dense":"","hide-details":""},model:{value:(item.Width),callback:function ($$v) {_vm.$set(item, "Width", $$v)},expression:"item.Width"}}):_vm._e()]}},{key:"item.Height",fn:function(ref){
var item = ref.item;
return [(!item.edited && item.Type === 'image')?_c('span',[_vm._v(" "+_vm._s(item.Height)+" ")]):(item.Type === 'image')?_c('v-text-field',{attrs:{"outlined":"","small":"","dense":"","hide-details":""},model:{value:(item.Height),callback:function ($$v) {_vm.$set(item, "Height", $$v)},expression:"item.Height"}}):_vm._e()]}},{key:"item.ORD",fn:function(ref){
var item = ref.item;
return [(!item.edited)?_c('span',[_vm._v(" "+_vm._s(item.ORD)+" ")]):_c('v-text-field',{attrs:{"outlined":"","small":"","dense":"","hide-details":""},model:{value:(item.ORD),callback:function ($$v) {_vm.$set(item, "ORD", $$v)},expression:"item.ORD"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.edited === null || item.edited === undefined)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleEditItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(item.edited === null || item.edited === undefined)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.handleDeleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e(),(item.edited)?_c('v-icon',{attrs:{"small":""},on:{"click":_vm.handleClose}},[_vm._v(" mdi-close ")]):_vm._e()]}}],null,false,342218280)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }