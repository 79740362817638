<template>
  <v-data-table
    v-if="items" 
    :headers="headers"
    :items="items"
    :items-per-page="20"
    class="elevation-1"
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Campos de formulario</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        />
        <v-spacer/>
          <v-btn 
            v-if="items.filter(x => x.edited).length === 0"
            color="primary" 
            dark 
            @click="handleAddItem"
            dense
          >
            A�adir
          </v-btn>
      </v-toolbar>
    </template>
  <template v-slot:item.Name="{ item }">
    <span
      v-if="!item.edited"
    >
    {{item.Name}}
    </span>
    <v-text-field
      v-else
      v-model="item.Name"
      outlined
      small
      dense
      hide-details
    />
  </template>
  <template v-slot:item.Class="{ item }">
    <span
      v-if="!item.edited"
    >
      {{item.Class}}
    </span>
    <v-text-field
      v-else
      v-model="item.Class"
      outlined
      small
      dense
      hide-details
    />
  </template>
  <template v-slot:item.Type="{ item }">
    <span
      v-if="!item.edited"
    >
      {{ getType(item.Type) }}
    </span>
    <v-select
      v-else
      :items="types"
      v-model="item.Type"
      outlined
      hide-details
      dense
      itemText="name"
      itemValue="id"
    />
  </template>
  <template v-slot:item.Value="{ item }">
    <span
      v-if="!item.edited && item.Type !== 'image'"
    >
      {{item.Value}}
    </span>
    <v-text-field
      v-else-if="item.Type !== 'image'"
      v-model="item.Value"
      outlined
      small
      dense
      hide-details
    />
  </template>
  <template v-slot:item.Size="{ item }">
    <span
      v-if="!item.edited && item.Type !== 'image'"
    >
      {{item.Size}}
    </span>
    <v-text-field
      v-else-if="item.Type !== 'image'"
      v-model="item.Size"
      outlined
      small
      dense
      hide-details
    />
  </template>
  <template v-slot:item.Width="{ item }">
    <span
      v-if="!item.edited && item.Type === 'image'"
    >
      {{item.Width}}
    </span>
    <v-text-field
      v-else-if="item.Type === 'image'"
      v-model="item.Width"
      outlined
      small
      dense
      hide-details
    />
  </template>
  <template v-slot:item.Height="{ item }">
    <span
      v-if="!item.edited && item.Type === 'image'"
    >
      {{item.Height}}
    </span>
    <v-text-field
      v-else-if="item.Type === 'image'"
      v-model="item.Height"
      outlined
      small
      dense
      hide-details
    />
  </template>
  <template v-slot:item.ORD="{ item }">
    <span
      v-if="!item.edited"
    >
      {{item.ORD}}
    </span>
    <v-text-field
      v-else
      v-model="item.ORD"
      outlined
      small
      dense
      hide-details
    />
  </template>
  <template v-slot:item.ORD="{ item }">
    <span
      v-if="!item.edited"
    >
      {{item.ORD}}
    </span>
    <v-text-field
      v-else
      v-model="item.ORD"
      outlined
      small
      dense
      hide-details
    />
  </template>
  <template v-slot:item.actions="{ item }">
      <v-icon
        v-if="item.edited === null || item.edited === undefined"
        small
        class="mr-2"
        @click="handleEditItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        v-if="item.edited === null || item.edited === undefined"
        small
        @click="handleDeleteItem(item)"
      >
        mdi-delete
      </v-icon>
      <v-icon
        v-if="item.edited"
        small
        @click="handleClose"
      >
        mdi-close
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
const defaultItem = [
      {
        ID: null,
        Name: 'Meeting',
        Class: 'meeting',
        Type: 'text',
        Size: 15,
        ORD: 0,
      },
      {
        ID: null,
        Name: 'Sala',
        Class: 'meetingRoom',
        Type: 'text',
        Size: 12,
        ORD: 1,
      },
      {
        ID: null,
        Name: 'Plta',
        Class: 'floor',
        Type: 'text',
        Size: 2,
        ORD: 2,
      },
      {
        ID: null,
        Name: 'Organizador',
        Class: 'organizer',
        Type: 'text',
        Size: 15,
        ORD: 3,
      },
      /*{
        ID: null,
        Name: 'Logo',
        Class: 'logo',
        Type: 'image',
        Width: '600',
        Height: '600',
        ORD: 4,
      },*/
    ]

import utils from '@/services/utils'
export default {
  props: {
    editedItem: {
      type: Array,
      default: null,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    items: null,
  }),
  computed: {
    headers () {
      return [
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'Name',
        },
        { text: 'Clase (style)', value: 'Class' },
        { text: 'Tipo', value: 'Type' },
        { text: 'Value', value: 'Value' },
        { text: 'Size', value: 'Size' },
        { text: 'Width', value: 'Width' },
        { text: 'Height', value: 'Height' },
        { text: 'Orden', value: 'ORD' },
        { text: 'Actions', value: 'actions', sortable: false },
      ]
    },
    types () {
      return [
        {
          id: 'text',
          name: 'texto corto'
        },
        {
          id: 'textarea',
          name: 'texto largo'
        },
        {
          id: 'image',
          name: 'imagen'
        }
      ]
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      if (this.editedItem) {
        this.items = JSON.parse(JSON.stringify(this.editedItem))
      } else {
        this.items = JSON.parse(JSON.stringify(defaultItem.map(item => {
          item.ID = utils.getNewID()
          return item
        })))
        this.handleChange()
      }
    },
    handleAddItem () {
      this.items.push({
        ID: utils.getNewID(),
        Name: 'Nombre',
        Class: 'name',
        Type: 'text',
        Size: 2,
        ORD: this.items.length,
        edited: true
      })
      this.handleChange()
    },
    handleEditItem (v) {
      this.items = this.items.map(item => {
        item.edited = item.ID === v.ID ? true : false
        return item
      })
      this.handleChange()
    },
    handleDeleteItem (v) {
      this.items = this.items.filter(x => x.ID !== v.ID)
      this.handleChange()
    },
    handleClose () {
      this.items = this.items.map(item => {
        item.edited = null
        return item
      })
      this.handleChange()
    },
    getType (v) {
      return this.types.filter(x => x.id === v).map(item => item.name).shift()
    },
    handleChange () {
      this.onChange(this.items)
    }
  }
}
</script>
